import React from 'react';
import styled from 'styled-components';

import { Any } from '../types/types';
import { Section } from '../styles/Section.styled';
import { Wrapper } from '../styles/Wrapper.styled';
import Layout from '~/components/Layout/Layout';
import { Cta } from '~/components/Generic/Cta/Cta';
import Metadata from '~/components/Metadata';

import ChevronIcon from '~/assets/images/icons/chevron.svg';

const StyleguidePage: React.FC<Any> = () => {
  return (
    <Layout>
      <Metadata title="Styleguide" />
      <StyledStyleguide>
        <Section gutter>
          <Wrapper>
            <h1>CTAs</h1>
            <h2>Types</h2>
            <Cta href="#">Regular button</Cta>
            <Cta href="#" variant="primary">
              Primary button
            </Cta>
            <Cta href="http://www.google.com">External link</Cta>
            <Cta href="/">Internal link</Cta>
            <Cta href="#" variant="secondary">
              Secondary button
            </Cta>

            <h2>Sizes</h2>
            <Cta href="#" size="small">
              Small
            </Cta>
            <Cta href="#">Regular</Cta>
            <Cta href="#" size="large">
              Large
            </Cta>
            <br />
            <Cta href="#" variant="primary" size="small">
              Primary small
            </Cta>
            <Cta href="#" variant="primary">
              Primary regular
            </Cta>
            <Cta href="#" variant="primary" size="large">
              Primary large
            </Cta>
            <br />
            <Cta href="#" variant="secondary" size="small">
              Secondary small
            </Cta>
            <Cta href="#" variant="secondary">
              Secondary regular
            </Cta>
            <Cta href="#" variant="secondary" size="large">
              Secondary large
            </Cta>

            <h2>Icons</h2>
            <Cta href="#" leftIcon={<ChevronIcon />}>
              Left Icon
            </Cta>
            <Cta href="#" rightIcon={<ChevronIcon />}>
              Right Icon
            </Cta>
          </Wrapper>
        </Section>
        <Section gutter color="white">
          <Wrapper bg="green">
            <h2>on dark BG</h2>
            <Cta href="#" invert>
              Inverted button
            </Cta>
            <Cta href="#" variant="primary">
              Primary button
            </Cta>
          </Wrapper>
        </Section>
        <Section gutter>
          <Wrapper>
            <h1>Headings</h1>
            <h1>
              H1 - Are you trying to target a specific state for your body with
              CBD? <em>We've got that!</em>
            </h1>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eaque
              ducimus quod modi, sint possimus exercitationem, qui repellat ut
              autem aperiam incidunt fugit quo cumque nam rem recusandae officia
              adipisci numquam!
            </p>
            <h2>H2 - This is a heading</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eaque
              ducimus quod modi, sint possimus exercitationem, qui repellat ut
              autem aperiam incidunt fugit quo cumque nam rem recusandae officia
              adipisci numquam!
            </p>
            <h3>H3 - This is a heading</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eaque
              ducimus quod modi, sint possimus exercitationem, qui repellat ut
              autem aperiam incidunt fugit quo cumque nam rem recusandae officia
              adipisci numquam!
            </p>
            <h4>H4 - This is a heading</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eaque
              ducimus quod modi, sint possimus exercitationem, qui repellat ut
              autem aperiam incidunt fugit quo cumque nam rem recusandae officia
              adipisci numquam!
            </p>
            <h5>H5 - This is a heading</h5>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eaque
              ducimus quod modi, sint possimus exercitationem, qui repellat ut
              autem aperiam incidunt fugit quo cumque nam rem recusandae officia
              adipisci numquam!
            </p>
            <h6>H6 - This is a heading</h6>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eaque
              ducimus quod modi, sint possimus exercitationem, qui repellat ut
              autem aperiam incidunt fugit quo cumque nam rem recusandae officia
              adipisci numquam!
            </p>
          </Wrapper>
        </Section>

        <Section gutter>
          <Wrapper bg="gray">
            <h1>Colors</h1>
            <div className="swatches">
              <ColorSwatch bgColor="var(--green)" textColor="var(--white);">
                Green
              </ColorSwatch>
              <ColorSwatch bgColor="var(--red)" textColor="var(--white);">
                Red
              </ColorSwatch>
              <ColorSwatch bgColor="var(--orange)" textColor="var(--black);">
                Orange
              </ColorSwatch>
              <ColorSwatch bgColor="var(--lime)" textColor="var(--black);">
                Lime
              </ColorSwatch>
              <ColorSwatch bgColor="var(--gray)" textColor="var(--black);">
                Gray
              </ColorSwatch>
            </div>
          </Wrapper>
        </Section>
        <Section gutter>
          <Wrapper>
            <h1>Inline Links</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eaque
              ducimus quod modi, sint <a href="#">possimus exercitationem</a>,
              qui repellat ut autem aperiam incidunt fugit quo cumque nam rem
              recusandae officia adipisci numquam!
            </p>
          </Wrapper>
        </Section>
      </StyledStyleguide>
    </Layout>
  );
};

const SwatchShape = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  border: 1px solid #eee;
  font-weight: 400;
  font-size: 1.1rem;
`;

const ColorSwatch = styled(SwatchShape)<{ bgColor: string; textColor: string }>`
  background: ${props => props.bgColor};
  color: ${props => props.textColor};
  border-radius: var(--radius);
`;

const StyledStyleguide = styled.div`
  .swatches {
    display: flex;
    > ${ColorSwatch} + ${ColorSwatch} {
      margin-left: var(--gutter);
    }
  }
`;

export default StyleguidePage;
